const primary = {
	main: '#3498db',
	dark: '#004081',
	light: '#337cc7',
	contrast: '#FFF',
	navbar: '#005CB9',
};
const secondary = {
	main: '#2E3D78',
	dark: '#2C6C85',
	light: '#2F5B8F',
	contrast: '#FFF',
};
const text = {
	primary: '#fff',
	secondary: 'rgba(255, 255, 255, 0.7)',
	disabled: 'rgba(255, 255, 255, 0.5)',
};

export const dark = {
	mode: 'dark', //input borders come from here! light vs anything else
	primary,
	secondary,
	text,
	divider: 'rgba(255, 255, 255, 0.12)',
	background: {
		default: '#121212',
		paper: '#121212',
		contrast: '#222222',
	},
	splash: {
		bg: {
			dark: '#004081',
			light: '#337cc7',
		},
	},
	error: {
		main: '#C44F2D',
		dark: '#9F2F0E',
		light: '#EF5350',
		contrast: '#FFF',
	},
	warning: {
		main: '#F19812',
		dark: '#D26D10',
		light: '#F19812',
		contrast: '#FFF',
	},
	info: {
		main: '#29B6F6',
		dark: '#0288D1',
		light: '#4FC3F7',
		contrast: '#FFF',
	},
	success: {
		main: '#66BB6A',
		dark: '#388E3C',
		light: '#81C784',
		contrast: '#FFF',
	},
	action: {
		active: text.secondary,
		disabled: text.disabled,
		// hover: '#f00',
		// selected: '#f00',
		// disabledBackground: '#f00',
		// focus: '#f00',
	},

	common: {
		black: '#ff0',
		white: '#ff0',
	},

	//seems not used
	grey: {
		// 50: '#f00',
		// 100: '#f00',
		// 200: '#f00',
		// 300: '#f00',
		// 400: '#f00',
		// 500: '#f00',
		// 600: '#f00',
		// 700: '#f00',
		// 800: '#f00',
		// 900: '#f00',
		// A100: '#f00',
		// A200: '#f00',
		// A400: '#f00',
		// A700: '#f00',
	},
};
