import { useState } from 'react';
import { BarBuilder } from './BarBuilder';

export function FunnelBars({ formattedGraphicalItems, _options } = {}) {
	const [activeKey, setActiveKey] = useState();

	const barData = formattedGraphicalItems[0].props.data;

	const funnelBarBuilder = new BarBuilder(_options);
	const bars = funnelBarBuilder.buildAll({
		barData,
		activeKey,
		setActiveKey,
	});

	return bars;
}
