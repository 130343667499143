import ReactJson from 'react18-json-view';
import 'react18-json-view/src/style.css';
import 'react18-json-view/src/dark.css';

export function JsonView(args = {}) {
	if (!args?.src) return;
	const options = {
		theme: args?.theme || 'a11y', //default | a11y | github | vscode | atom| winter-is-coming
		dark: args?.dark || false,
		displayArrayKey: args?.displayArrayKey || false,
		quotesOnKeys: args?.quotesOnKeys || false,
		displayDataTypes: args?.displayDataTypes || false,
		displayObjectSize: args?.displayObjectSize || false,
		enableClipboard: args?.enableClipboard || true,
		name: null,
	};
	const style = {
		fontSize: args?.fontSize || '0.75rem',
		padding: args?.padding || '0.75rem',
		background: args?.background || '#8881',
		borderRadius: args?.borderRadius || '1em',
	};
	if (!['false', 'undefined'].includes(args?.debug + '')) {
		console.log({ _: 'JsonView', args, options, style });
	}
	return (
		<div style={style}>
			<ReactJson src={args.src} {...options} />
		</div>
	);
}
