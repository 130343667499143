class BarInfoCalculator {
	options = {};

	constructor(barOptions) {
		this.options = barOptions;
	}

	calculate(barData, index, additionalOpts = {}) {
		const y = this.#getY(barData);
		const x = this.#getX(index, additionalOpts);
		const height = this.#getHeight(barData);
		const color = this.#getColor(barData, additionalOpts);

		return {
			x,
			y,
			height,
			color,
		};
	}

	#getY({ height, y }) {
		if (height > this.options.maxBarHeight) {
			return this.options.maxBarY;
		}

		return y + 80;
	}
	#getHeight({ height }) {
		if (height > this.options.maxBarHeight) {
			return this.options.maxBarHeight;
		}

		return height;
	}
	#getX(index, additionalOpts) {
		if (typeof additionalOpts?.isProspect === 'boolean') {
			const amountOfBarsToTheLeft = index * 2;

			const distance = amountOfBarsToTheLeft * this.options.barWidth;

			return additionalOpts.isProspect
				? distance + this.options.barWidth
				: distance;
		}

		const gap = this.options.barGap || 101;
		return index * this.options.barWidth + gap * index;
	}
	#getColor({ key }, additionalOpts) {
		if (additionalOpts.isProspect === true) {
			return this.options.keyToColor[`${key} Prospect`];
		}
		if (additionalOpts.isProspect === false) {
			return this.options.keyToColor[`${key} Active`];
		}

		return this.options.keyToColor[key];
	}
}

export { BarInfoCalculator };
