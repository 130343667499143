import React, { createRef, useEffect, useMemo } from 'react';
import { StrGantt } from 'gantt';

const DAY = 24 * 3600 * 1000;

const data = [
	['init  ', '2022-06-01', '2023-06-01', 0, 'milestone'],
	['goals ', '2023-03-27', '2023-03-27', 0, 'milestone'],
	['we v? ', '2023-05-24', '2023-05-24', 0, 'milestone'],
	['v0.5  ', '2023-06-01', '2023-08-07', 1],
	['v0.6  ', '2023-08-07', '2023-09-11', 1],
	['v0.6.5', '2023-09-11', '2023-10-16', 1],
	['v0.7  ', '2023-10-16', '2023-11-19', 0.7],
	['thanks', '2023-11-19', '2023-11-26', 0, 'group'],
	['v0.8  ', '2023-11-26', '2023-12-18', 0],
	['xmasny', '2023-12-18', '2024-01-01', 0, 'group'],
	['v0.9  ', '2024-01-01', '2024-02-01', 0],
	['v0.9.5', '2024-02-01', '2024-03-01', 0],
	['beta  ', '2024-03-01', '2024-04-01', 0, 'milestone'],
	['v1.0  ', '2024-03-01', '2024-04-01', 0],
].map(([text, start, end, percent, type], i) => ({
	text:
		type !== 'milestone'
			? `${text} - ${(new Date(end) - new Date(start)) / DAY}d`
			: text,
	start: new Date(start),
	end: new Date(end),
	percent,
	type,
}));

const styleOptions = {
	// bgColor: string,           // default: '#fff'
	// lineColor: string,         // default: '#eee'
	// redLineColor: string,      // default: '#f04134'
	// groupBack: string,         // default: '#3db9d3'
	// groupFront: string,        // default: '#299cb4'
	// taskBack: string,          // default: '#65c16f'
	// taskFront: string,         // default: '#46ad51'
	// milestone: string,         // default: '#d33daf'
	// warning: string,           // default: '#faad14'
	// danger: string,            // default: '#f5222d'
	// link: string,              // default: '#ffa011'
	// textColor: string,         // default: '#222'
	// lightTextColor: string,    // default: '#999'
	// lineWidth: string,         // default: '1px'
	// thickLineWidth: string,    // default: '1.4px'
	// fontSize: string,          // default: '14px'
	// smallFontSize: string,     // default: '12px'
	// fontFamily: string,        // default: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
	groupBack: 'pink',
	taskBack: '#dadada',
	fontSize: '1.15em',
	smallFontSize: '1em',
};

const options = {
	// viewMode: 'day' | 'week' | 'month',
	// offsetY: number,               // default: 60,
	// rowHeight: number,             // default: 40,
	// barHeight: number,             // default: 16,
	// thickWidth: number,            // default: 1.4,
	// styleOptions: StyleOptions
	styleOptions,
	viewMode: 'week',
	rowHeight: 60,
	barHeight: 40,
};

function GanttComponent({ data }) {
	const ganttRef = createRef();

	const gantt = useMemo(() => {
		return new StrGantt(data, options);
	}, [data]);

	useEffect(() => {
		ganttRef.current.innerHTML = gantt.render();
		const svg = ganttRef.current.querySelector('svg');
		svg.removeAttribute('width');
		//svg.removeAttribute('height');
	});

	useEffect(() => {
		gantt.setData(data);
	}, [data, gantt]);

	return <div style={{ overflowX: 'scroll' }} ref={ganttRef} />;
}

export const Gantt = (args) => {
	return <GanttComponent data={data} />;
};
