/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-restricted-globals  */

export default () => {
	const getNotificationsRequest = {
		body: JSON.stringify({
			operationName: 'submitForm',
			variables: {
				input: [
					{
						name: 'ui.sp_Upsert',
						call: 'notifications',
						args: {
							operation: 'get',
						},
					},
				],
			},
		}),
		cache: 'default',
		credentials: 'include',
		headers: {
			Accept: '*/*',
			'Accept-Language': 'en-US,en;q=0.9',
			'Content-Type': 'application/json',
		},
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		referrer: self.location.origin,
		referrerPolicy: 'strict-origin-when-cross-origin',
	};
	async function getNotifications() {
		return fetch(
			self.location.origin + '/api/graphql?call:notifications',
			getNotificationsRequest,
		)
			.then((x) => x.json())
			.then((x) => JSON.parse(x?.data.FormProc?.[0]?.results))
			.catch((x) => []);
	}

	const knownEvents = {
		GET_NOTIFICATIONS: getNotifications,
	};

	self.addEventListener('message', async (event) => {
		if (!event) return;
		const eventData = event.data;

		if (!(eventData?.eventCode in knownEvents)) return;

		try {
			const eventHandler = knownEvents[eventData.eventCode];

			const result = await eventHandler();
			postMessage({ ...eventData, result });
		} catch (error) {
			postMessage({ error });
		}
	});
};
