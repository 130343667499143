import React from 'react';
import { createRoot } from 'react-dom/client';

import { App, WorkerAttacher } from '@anthroware_dev/web/client';
import { theme } from './theme/theme';
import '@anthroware_dev/web/client.css';
import { Gantt } from './components/Gantt';

import rolesMap from './rolesMap';

import { FunnelViz } from './components/FunnelViz';
import { FunnelTalentRecruitingTabDataViz } from './components/Dashboards/RecruitingTab/TalentFunnel';
import { BranchTotalsLinearViz } from './components/Dashboards/RecruitingTab/BranchTotalsLinear';
import { TimeFrameTotalsViz } from './components/Dashboards/BranchManagement/TimeFrameTotalsViz';
import { PieChartViz } from './components/TalentsViz/PieChart';
import { PotentialClientsViz } from './components/ClientsViz/PotentialViz';
import { FilePicker } from './components/FilePicker';
import { Signature } from './components/Signature';
import { JsonView } from './components/JsonView';

import notificationsWorker from './notificationsWorker';
import './App.css';

const container = document.querySelector('#root');
const root = createRoot(container);
const components = {
	Gantt,

	// clients viz (must use generic viz's)
	PotentialClientsViz,

	// talents viz
	PieChartViz,

	// generic funnel viz
	FunnelViz,

	// dashboards
	FunnelTalentRecruitingTabDataViz,
	BranchTotalsLinearViz,
	TimeFrameTotalsViz,

	FilePicker,
	Signature,
	JsonView,
};

let page = {};
const showInvite = document.location.pathname.startsWith('/invite');
if (showInvite) {
	page = {
		fragment: 'userInvite',
		authorized: false,
		title: 'Greenfield · Invite',
	};
}
const showApplication = document.location.pathname.startsWith('/apply');
if (showApplication) {
	page = {
		fragment: 'userApply',
		authorized: false,
		title: 'Greenfield · Apply',
	};
}
const showJobBoard = document.location.pathname.startsWith('/jobs');
if (showJobBoard) {
	page = {
		fragment: 'applicationBoard',
		authorized: false,
		title: 'Greenfield · Jobs',
	};
}

if (page?.authorized !== false) {
	let amountOfCalls = 0;
	const getCookie = (name) => {
		try {
			const value = (
				`;;${document.cookie};;`
					.split(';')
					.find((cookie) => (cookie || '').trim().startsWith(name)) ||
				''
			).trim();
			return value ? value.split('=')[1] : undefined;
		} catch (e) {}
	};
	WorkerAttacher.poll(notificationsWorker, {
		startAt: 5000,
		every: 20000,
		eventCode: 'GET_NOTIFICATIONS',
		shouldPoll: ({ StateManager }) => {
			try {
				const currentUser = StateManager.get('currentUser');
				if (!(currentUser?.roles || []).length) return false;

				const sessionExpiresCookie = getCookie('sessionExpires') || '0';
				const sessionExpired =
					Number(sessionExpiresCookie) <= Date.now();
				if (sessionExpired) return false;

				return true;
			} catch (e) {
				return false;
			}
		},
		onMessageCb: ({ event, StateManager }) => {
			amountOfCalls += 1;
			const notifications = event.data.result || [];

			const shouldSkip =
				!Array.isArray(notifications) ||
				// notifications.length === 0 ||
				amountOfCalls === 0;

			if (shouldSkip) return;

			StateManager.update('_internal:notifications', {
				amountOfCalls,
				notifications,
			});
		},
		onErrorCb: ({ event, toast }) => {
			// toast.error(
			// 	'An error happened while requesting new notifications. We will try again.',
			// );
			console.log('Notifications Worker failed with:', event.data.error);
		},
	});
}

root.render(<App {...{ components, theme, page, rolesMap }} />);
