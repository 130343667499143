import { useState } from 'react';
import { Rectangle } from 'recharts';
import { getBarColor, getBarHeight, getBarX, getBarY } from '../utils';

export function Bars({ formattedGraphicalItems, options, ...rest }) {
	const [activeKey, setActiveKey] = useState();

	const actualClients_BarData = formattedGraphicalItems[0].props.data;
	const prospectClients_BarData = formattedGraphicalItems[1].props.data;

	const getBarInfo = (index, isProspect = false) => {
		if (isProspect) {
			const p_data = prospectClients_BarData[index];
			const p_fill = getBarColor(p_data, true, options);
			const key = p_data.key + '-Prospect';
			const p_opacity = activeKey && activeKey === key ? 0.75 : 1;
			return {
				x: getBarX(index, true, options),
				y: getBarY(p_data, 0, options),
				height: getBarHeight(p_data, options),
				fill: p_fill,
				opacity: p_opacity,
				p_barData: p_data,
				key: key,
			};
		}

		const a_data = actualClients_BarData[index];
		const a_fill = getBarColor(a_data, false, options);
		const key = a_data.key + '-Active';
		const a_opacity = activeKey && activeKey === key ? 0.75 : 1;
		return {
			x: getBarX(index, false, options),
			y: getBarY(a_data, 0, options),
			height: getBarHeight(a_data, options),
			fill: a_fill,
			opacity: a_opacity,
			a_barData: a_data,
			key: key,
		};
	};

	const onClickBar = (data, isProspect = false, e) => {
		if (isProspect) {
			window.alert(`${data?.key} - ${data?.prospectAmount} PROSPECT`);
			return;
		}

		window.alert(`${data?.key} - ${data?.actualAmount} ACTUAL`);
	};

	return actualClients_BarData?.map((_, index) => {
		const isFirstItem = index === 0;
		const isLastItem = index === actualClients_BarData.length - 1;

		const { a_barData, ...a_props } = getBarInfo(index);
		const { p_barData, ...p_props } = getBarInfo(index, 'isProspect');

		const a_amountOfZeros = String(a_barData.actualAmount).length;
		const p_amountOfZeros = String(p_barData.prospectAmount).length;

		return (
			<>
				<text
					y={26}
					x={a_props.x}
					fontSize="16"
					fontWeight="400"
					fontFamily="sans-serif"
					fill="#000000dd"
				>
					<tspan x={a_props.x + (isFirstItem ? 0 : 10)}>
						{a_barData.key} Clients
					</tspan>
				</text>

				<text
					x={a_props.x + 20 + a_amountOfZeros * 5}
					y={265}
					fill={a_props.fill}
					fontSize="35px"
					fontWeight="500"
					fontFamily="sans-serif"
					dominantBaseline="middle"
					textAnchor="middle"
					letterSpacing="1px"
				>
					{a_barData.actualAmount}
				</text>

				<text
					x={a_props.x + 32}
					y={295}
					fill="#606060"
					fontFamily="sans-serif"
					textAnchor="middle"
					fontWeight="100"
				>
					Actual
				</text>

				<Rectangle
					width={options.barWidth}
					onClick={() => onClickBar(a_barData, false)}
					onMouseEnter={() => setActiveKey(a_props.key)}
					onMouseLeave={() => setActiveKey(undefined)}
					cursor="pointer"
					{...a_props}
				/>

				<text
					x={p_props.x + 20 + p_amountOfZeros * 5}
					y={265}
					fill={p_props.fill}
					fontSize="35px"
					fontWeight="500"
					fontFamily="sans-serif"
					dominantBaseline="middle"
					textAnchor="middle"
					letterSpacing="1px"
				>
					{p_barData.prospectAmount}
				</text>

				<text
					x={p_props.x + 40}
					y={295}
					fill="#606060"
					fontFamily="sans-serif"
					textAnchor="middle"
					fontWeight="100"
				>
					Prospect
				</text>

				<Rectangle
					width={options.barWidth}
					onClick={() => onClickBar(p_barData, true)}
					onMouseEnter={() => setActiveKey(p_props.key)}
					onMouseLeave={() => setActiveKey(undefined)}
					cursor="pointer"
					{...p_props}
				/>

				{!isLastItem && (
					<svg
						width="2"
						height="305"
						viewBox="0 0 2 260"
						fill="none"
						x={p_props.x + options.barWidth - 1}
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="0.5" width="1" height="290" fill="#90A4AE" />
					</svg>
				)}
			</>
		);
	});
}
