import { Rectangle } from 'recharts';
import { BarInfoCalculator } from '../../lib/BarInfoCalculator';

class BarBuilder {
	options = {};
	barInfoCalculator;

	constructor(options) {
		this.options = options;
		this.barInfoCalculator = new BarInfoCalculator(options);
	}

	#buildBar(barInfo) {
		const {
			barX,
			barY,
			nextBarX,
			nextBarY,
			barColor,
			barHeight,
			opacity,
			currentBarData,
			setActiveKey,
			isLastItem,
		} = barInfo;

		const getTrendCoordinates = () => {
			const firstPoint = { x: barX + this.options.barWidth + 2, y: 1000 };
			const secondPoint = { x: nextBarX + 3, y: nextBarY + 1000 };
			const thirdPoint = { x: nextBarX + 3, y: nextBarY + 1 };
			const fourthPoint = {
				x: barX + this.options.barWidth + 2,
				y: barY + 1,
			};

			return `
        ${firstPoint.x},${firstPoint.y}
        ${secondPoint.x},${secondPoint.y}
        ${thirdPoint.x},${thirdPoint.y}
        ${fourthPoint.x},${fourthPoint.y}
      `;
		};

		const spacingBar = (
			<svg
				width="2"
				height={this.options.maxBarHeight + 20}
				viewBox={`0 0 2 ${this.options.maxBarHeight + 20}`}
				y="80"
				x={barX}
				fill="#101010"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="0.5" width="1" height="230" fill="#90A4AE" />
			</svg>
		);

		const label = (
			<text
				y={26}
				x={barX}
				fontSize="16"
				fontWeight="400"
				fontFamily="sans-serif"
				style={{ fill: 'rgba(0, 0, 0, 0.87)' }}
			>
				{currentBarData.preKey ? (
					<>
						<tspan x={barX + 15}>{currentBarData.preKey}</tspan>
						<tspan x={barX + 15} y={50}>
							{currentBarData.key}
						</tspan>
					</>
				) : (
					<tspan x={barX + 15}>{currentBarData.key}</tspan>
				)}
			</text>
		);

		const amount = (
			<text
				x={barX + 35}
				y={currentBarData.preKey ? 79 : 55}
				fill={barColor}
				fontSize="35px"
				fontWeight="500"
				fontFamily="sans-serif"
				dominantBaseline="middle"
				textAnchor="middle"
				letterSpacing="1px"
			>
				{currentBarData.amount}
			</text>
		);

		const amountBar = (
			<Rectangle
				width={this.options.barWidth}
				height={barHeight}
				y={barY}
				x={barX + 2}
				fill={barColor}
				opacity={opacity}
				cursor="pointer"
				onClick={(e) =>
					window.alert(
						`${currentBarData.key} - ${currentBarData.amount}`,
					)
				}
				onMouseEnter={(e) => setActiveKey(currentBarData.key)}
				onMouseLeave={() => setActiveKey(undefined)}
			/>
		);

		const trendBar = !isLastItem ? (
			<polygon points={getTrendCoordinates()} fill="#e5e5e5" />
		) : null;

		return (
			<>
				{spacingBar}
				{label}
				{amount}
				{amountBar}
				{trendBar}
			</>
		);
	}

	buildAll({ barData, activeKey, setActiveKey }) {
		const allBars = [];

		for (let i = 0; i < barData.length; i += 1) {
			const currentBarInfo = this.barInfoCalculator.calculate(
				barData[i],
				i,
			);

			const isLastItem = i === barData.length - 1;

			const nextBarInfo = !isLastItem
				? this.barInfoCalculator.calculate(barData[i + 1], i + 1)
				: {};

			const bar = this.#buildBar(
				{
					barY: currentBarInfo.y,
					barX: currentBarInfo.x,
					barHeight: currentBarInfo.height,
					barColor: currentBarInfo.color,
					nextBarX: nextBarInfo.x,
					nextBarY: nextBarInfo.y,
					opacity:
						activeKey && activeKey === barData[i].key ? 0.5 : 1,
					setActiveKey,
					currentBarData: barData[i],
					isLastItem,
				},
				this.options,
			);

			allBars.push(bar);
		}
		return allBars;
	}
}

export { BarBuilder };
