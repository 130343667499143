import {
	Line,
	XAxis,
	YAxis,
	LineChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';

export function BranchTotalsLinearComponent({ data }) {
	return (
		<ResponsiveContainer width="100%" height={240}>
			<LineChart
				data={data}
				margin={{
					top: 5,
					left: -20,
				}}
			>
				<CartesianGrid
					strokeDasharray="0.1 0.1"
					strokeOpacity={0.3}
					fill="#fff"
				/>
				<XAxis
					dataKey="label"
					axisLine={false}
					tickLine={false}
					tickMargin={5}
				/>
				<YAxis axisLine={false} tickLine={false} />
				<Tooltip />
				<Line type="monotone" dataKey="StartAmount" stroke="#3f8d55" />
				<Line type="monotone" dataKey="EndAmount" stroke="#9f2f0e" />
			</LineChart>
		</ResponsiveContainer>
	);
}

export function BranchTotalsLinearViz({ data }) {
	if (!data || !Array.isArray(data)) return 'Loading...';

	const _data = data.sort((a, b) => Number(a.rowID) - Number(b.rowID));

	return <BranchTotalsLinearComponent data={_data} />;
}
