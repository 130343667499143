export const getTextPosition = (x, amount, index, isProspect) => {
	if (typeof isProspect === 'boolean') {
		let amountX = isProspect ? x + 12 : x;

		if (amount === 1) {
			amountX -= 3;
		}
		if (amount >= 10) {
			amountX += 13;
		}
		if (amount >= 100) {
			amountX += 5;
		}
		if (amount > 100) {
			amountX += 15;
		}

		return {
			amountX,
			labelX: isProspect ? x + 35 : x + 12,
		};
	}

	let amountX = 34;
	let labelX = 62;

	if (index > 0) {
		amountX += index * 170;
		labelX += index * 170;
	}

	return { amountX, labelX };
};
export const getBarY = ({ height, y }, additionalHeightGap = 0, options) => {
	if (height > options.maxBarHeight) {
		return options.maxBarY;
	}

	return y + additionalHeightGap;
};
export const getBarHeight = ({ height }, options) => {
	if (height > options.maxBarHeight) {
		return options.maxBarHeight;
	}

	return height;
};
export const getBarX = (index, isProspect, options) => {
	if (typeof isProspect === 'undefined') {
		const gap = 30;
		return index * options.barWidth + gap * index;
	}

	const amountOfBarsToTheLeft = index * 2;

	const distance = amountOfBarsToTheLeft * options.barWidth;

	return isProspect ? distance + options.barWidth : distance;
};
export const getBarColor = ({ key }, isProspect, options) => {
	if (typeof isProspect === 'boolean') {
		return isProspect
			? options.keyToColor[`${key} Prospect`]
			: options.keyToColor[`${key} Active`];
	}

	return options.keyToColor[key];
};
