import { getLogo } from './logo';
import { getPalette } from './palette';

export const theme = (mode, theme) => {
	const logo = getLogo(mode);
	const palette = getPalette(mode);

	// breakpoints
	// 	"xs": 0,
	// 	"sm": 600,
	// 	"md": 900,
	// 	"lg": 1200,
	// 	"xl": 1536

	// h1 is 2 em
	// h2 is 1.5 em
	// h3 is 1.3 em
	// h4 is 1 em
	// h5 is 0.8 em
	// h6 is 0.7 em

	const menuShadowColor = 'rgba(0, 0, 0, 0.18)';

	return {
		logo,
		palette,

		typography: {
			fontFamily: ['Open Sans', 'sans-serif'].join(),
			htmlFontSize: 16,
			h1: {
				fontSize: '2rem',
			},
			h2: {
				fontSize: '1.75rem',
			},
			h3: {
				fontSize: '1.45rem',
			},
			h4: {
				fontSize: '1.3rem',
			},
			h5: {
				fontSize: '1.2rem',
			},
			h6: {
				fontSize: '1.15rem',
			},
			body1: {
				// fontWeight: '400',
				fontSize: '1rem',
				// lineHeight: '150%',
				color: palette.text.primary,
				[theme.breakpoints.down('md')]: {
					fontSize: '0.8rem',
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '0.9rem',
				},
			},
			body2: {
				// fontWeight: '400',
				fontSize: '0.875rem',
				// lineHeight: '143%',
				color: palette.text.secondary,
				[theme.breakpoints.down('md')]: {
					fontSize: '0.6rem',
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '0.7rem',
				},
			},
			subtitle1: {
				fontWeight: '400',
				fontSize: '1rem',
				lineHeight: '175%',
				color: palette.text.primary,
			},
			subtitle2: {
				fontWeight: '500',
				fontSize: '0.875rem',
				lineHeight: '157%',
				color: palette.text.primary,
			},
			overline: {
				fontWeight: '400',
				fontSize: '0.75rem',
				lineHeight: '266%',
				color: palette.text.primary,
			},
			caption: {
				fontWeight: '400',
				fontSize: '0.75rem',
				lineHeight: '166%',
				color: palette.text.primary,
			},
			totalsItemVizValue: {
				fontSize: '56px',
				fontStyle: 'normal',
				fontWeight: '400',
				lineHeight: '116.7%',
				letterSpacing: '-1.5px',
				margin: '0',
				color: '#005CB9',
			},
		},
		components: {
			MuiStack: {
				styleOverrides: {
					root: {},
				},
				variants: [
					{
						props: { variant: 'main' },
						style: {
							flex: 1,
							overflow: 'hidden',
						},
					},
					{
						props: { variant: 'body' },
						style: {
							flex: 1,
							flexDirection: 'row',
							overflow: 'hidden',
							width: '100vw',
							backgroundColor: palette.background.paper,
						},
					},
					{
						props: { variant: 'content' },
						style: {
							flexDirection: 'row',
							overflowY: 'hidden',
							overflowX: 'hidden',
							flex: 1,
						},
					},
					{
						props: { variant: 'page' },
						style: {
							flex: 1,
							overflowY: 'hidden',
							display: 'flex',
							flexDirection: 'column',
							[theme.breakpoints.up('xs')]: {
								paddingBlock: '8px',
							},
							[theme.breakpoints.up('md')]: {
								paddingBlock: '16px',
							},
							[theme.breakpoints.up('lg')]: {
								paddingBlock: '16px',
							},
						},
					},
					{
						props: { variant: 'sidebar-right' },
						style: {
							'--sidebar-right-collapsed-width': '70px',
							'--sidebar-right-width': '260px',
							'--shadow-color': menuShadowColor,
							boxShadow: '-5px 0px 7px 0px var(--shadow-color)',
							[theme.breakpoints.up('xs')]: {
								display: 'none',
							},
							[theme.breakpoints.up('md')]: {
								display: 'flex',
							},
						},
					},
					{
						props: { variant: 'page-header' },
						style: {
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginTop: '0.5em',
							marginBottom: '1.25em',
							width: '100%',
							[theme.breakpoints.up('xs')]: {
								paddingInline: '16px',
							},
							[theme.breakpoints.up('md')]: {
								paddingInline: '32px',
							},
							[theme.breakpoints.up('lg')]: {
								paddingInline: '64px',
							},
						},
					},
					{
						props: { variant: 'page-content' },
						style: {
							flex: 1,
							paddingTop: '0.5em',
							overflowY: 'auto',
							[theme.breakpoints.up('xs')]: {
								paddingInline: '16px',
								minWidth: '100%',
								marginLeft: 'auto',
								marginRight: 'auto',
							},
							[theme.breakpoints.up('md')]: {
								paddingInline: '32px',
								minWidth: '670px',
							},
							[theme.breakpoints.up('lg')]: {
								paddingInline: '64px',
								marginLeft: 'unset',
								marginRight: 'unset',
							},
						},
					},
				],
			},
			MuiIcon: {
				variants: [
					{
						props: { fontSize: 'x-large' },
						style: {
							fontSize: '3rem',
						},
					},
				],
				htmlColor: palette.text.primary,
				color: palette.text.primary,
			},
			MuiListItemIcon: {
				htmlColor: palette.text.primary,
				color: palette.text.primary,
				styleOverrides: {
					root: {
						minWidth: 32,
					},
				},
			},
			MuiSvgIcon: {
				htmlColor: palette.text.primary,
				color: palette.text.primary,
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						overflow: 'hidden',
						overflowX: 'hidden',
						overflowY: 'hidden',
						display: 'flex',
						flexDirection: 'column',
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						colorScheme: palette.mode || 'light',
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						[`&.Mui-disabled`]: {
							opacity: 0.5,
						},
					},
				},
			},
		},
	};
};
