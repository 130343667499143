import { Bar, XAxis, BarChart, Customized } from 'recharts';
import { FunnelBars } from './Bars';

function FunnelVizComponent({ data, options }) {
	return (
		<BarChart
			data={data}
			height={310}
			width={options.width}
			barSize={options.barWidth}
			margin={options.margin}
		>
			<XAxis
				dataKey="key"
				allowDecimals={true}
				axisLine={false}
				tickLine={false}
				tickFormatter={() => ''}
			/>
			<Bar dataKey="amount" fill="#FAFAFA" />
			<Customized component={FunnelBars} _options={options} />
		</BarChart>
	);
}

export function FunnelViz({
	defaultData,
	data,
	options,
	preKeyName,
	keyName,
	amountName,
}) {
	let _data = defaultData;

	if (Array.isArray(data)) {
		_data = data.map((x) => ({
			preKey: x[preKeyName],
			key: x[keyName],
			amount: x[amountName],
		}));
	}

	if (!_data || !options) {
		return 'Loading...';
	}

	return (
		<FunnelVizComponent data={structuredClone(_data)} options={options} />
	);
}
