import { FunnelViz } from '../../FunnelViz';

export function FunnelTalentRecruitingTabDataViz({
	data,
	options,
	defaultData,
}) {
	let _data = defaultData;

	if (Array.isArray(data)) {
		_data = data.map((x) => ({
			preKey: x.UserType === 'Talent' ? 'Applicant:' : 'Recruiter:',
			key: x.stepname,
			amount: x.cntappsinstep,
		}));
	}

	if (!options || !data) {
		return <div style={{ height: 300 }}>Loading...</div>;
	}

	return <FunnelViz defaultData={_data} options={options} />;
}
