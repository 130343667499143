import { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export const Signature = (args) => {
	const {
		width = '800px',
		height = '200px',
		border = '1px solid currentColor',
		borderRadius = '5px',
		label = 'Signature',
		display = 'flex',
		flexDirection = 'column',
		value,
		readonly,
		onUpdate,
	} = args;
	//const [dims, setDims] = useState({});
	const [sigRef, setSigRef] = useState();
	const ref = useRef();

	// useEffect(() => {
	// 	if (readonly) return;
	// 	setDims(ref.current.getBoundingClientRect());
	// }, [ref]);

	useEffect(() => {
		if (!sigRef) return;
		if (readonly) sigRef.off();
		if (value) {
			sigRef.fromDataURL(value);
		}
	}, [sigRef, value, readonly]);

	const clear = () => {
		sigRef.clear();
	};
	const trim = () => {
		const dataUri = sigRef.getTrimmedCanvas().toDataURL('image/png');
		clear();
		sigRef.fromDataURL(dataUri);
	};
	const save = () => {
		//const dataUri = sigRef.getTrimmedCanvas().toDataURL('image/png');
		const dataUri = sigRef.toDataURL('image/png');
		onUpdate({
			signature: dataUri,
			signatureFiles: [{ name: 'signature', value: dataUri }],
		});
	};

	if (readonly && value) {
		return (
			<div>
				<label style={{ display: 'block', marginBottom: '0.5em' }}>
					{label}
				</label>
				<div
					ref={ref}
					style={{
						border,
						borderRadius,
						width,
						height,
						display,
						flexDirection,
					}}
				>
					<img src={value} alt="signature" />
				</div>
			</div>
		);
	}

	return (
		<div>
			<label style={{ display: 'block', marginBottom: '0.5em' }}>
				{label}
			</label>
			<div
				ref={ref}
				style={{
					border,
					borderRadius,
					width,
					height,
					display,
					flexDirection,
				}}
			>
				<SignatureCanvas
					ref={setSigRef}
					penColor="black"
					canvasProps={{
						// width: dims.width,
						// height: dims.height,
						width: 800,
						height: 200,
						className: 'sigCanvas',
					}}
				/>
			</div>
			<div>
				<button onClick={clear}>Clear</button>
				<button onClick={trim}>Trim</button>
				<button onClick={save}>Save</button>
			</div>
		</div>
	);
};
