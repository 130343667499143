// prettier-ignore
const userRoleMap = {
    // "parent" roles
    1:  { key: 'PlatformAdministrator',  isParent: 1, dashboard: 'Platform' },
	3:  { key: 'Accounting',             isParent: 1, dashboard: 'Accounting' },
	4:  { key: 'OrganizationManagement', isParent: 1, dashboard: 'Organization' },
	7:  { key: 'Performance',            isParent: 1, dashboard: 'Performance' },
	8:  { key: 'Oversight',              isParent: 1, dashboard: 'Oversight' },
	18: { key: 'IT',                     isParent: 1, dashboard: 'Organization' },
	19: { key: 'Executive',              isParent: 1, dashboard: 'Oversight' },

    // "child" roles
    9:  { key: 'Salesperson',            isParent: 0, dashboard: 'Sales' },
	10: { key: 'AccountManager',         isParent: 0, dashboard: 'Accounts' },
	11: { key: 'Recruiter',              isParent: 0, dashboard: 'Recruiting' },
	12: { key: 'BranchManager',          isParent: 0, dashboard: 'Branch' },
	13: { key: 'PayrollSpecialist',      isParent: 0, dashboard: 'Payroll' },
	15: { key: 'FranchiseOwner',         isParent: 0, dashboard: 'Branch' },
	16: { key: 'Talent',                 isParent: 0 },
	17: { key: 'Client',                 isParent: 0, dashboard: 'Client' },

    // commented roles are considered deprecated, do not support!
    //  2:  { key: 'Parent',                 isParent: 0 },
    //  5:  { key: 'Child',                  isParent: 0 },
    //  14: { key: 'OfficeCoordinator',      isParent: 0 },
};

export default userRoleMap;
