import { Bar, XAxis, BarChart, Customized } from 'recharts';

import { Bars } from './Bars';

function PotentialClientsVizComponent({ data, options }) {
	return (
		<BarChart
			data={data}
			height={310}
			width={options.width}
			barSize={options.barWidth}
			margin={options.margin}
		>
			<XAxis
				dataKey="key"
				allowDecimals={true}
				axisLine={false}
				tickLine={false}
				tickFormatter={() => ''}
			/>
			<Bar dataKey="actualAmount" fill="#FAFAFA" />
			<Bar dataKey="prospectAmount" fill="#FAFAFA" />
			<Customized component={Bars} options={options} />
		</BarChart>
	);
}

/**
 * 1 - responsiveness (changing width of chart and bars based on window width and also options)
 * 2 -
 * 3 - without useless bars (filled with #FAFAFA);
 */

export function PotentialClientsViz({ data, options }) {
	const getAmount = (field) => {
		if (!Array.isArray(data)) return '';

		return data[0][field];
	};

	if (!data || !options) {
		return 'Loading...';
	}

	const _data = [
		{
			key: 'Tier 1',
			prospectAmount: getAmount('t1pCnt'),
			actualAmount: getAmount('t1aCnt'),
		},
		{
			key: 'Tier 2',
			prospectAmount: getAmount('t2pCnt'),
			actualAmount: getAmount('t2aCnt'),
		},
		{
			key: 'Tier 3',
			prospectAmount: getAmount('t3pCnt'),
			actualAmount: getAmount('t3aCnt'),
		},
		{
			key: 'Tier 4',
			prospectAmount: getAmount('t4pCnt'),
			actualAmount: getAmount('t4aCnt'),
		},
	];

	return <PotentialClientsVizComponent data={_data} options={options} />;
}
