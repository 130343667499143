import { useState } from 'react';

const convertFileList = async (fileList, callback) => {
	const converted = [];
	for (const file of fileList) {
		await new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				() => {
					converted.push({
						name: file.name,
						value: reader.result,
						//
					});
					resolve();
				},
				false,
			);
			reader.readAsDataURL(file);
		});
	}
	callback({ converted });
};
export const FilePicker = (args) => {
	const [filenames, setFileNames] = useState('');
	const { selectFileFn, dataName, label, textContent, native } = args;
	const pickerLabel = label || textContent || 'Select File(s)';
	const onChange = (e) => {
		const { files } = e.target;
		if (files.length <= 0) return;
		convertFileList(files, ({ converted }) => {
			console.log({ converted });
			selectFileFn({ files: converted, dataName });
			setFileNames(converted.map((x) => x.name).join(', '));
		});
	};
	const nativeInputStyle = {};
	const labelStyle = {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		boxSizing: 'border-box',
		backgroundColor: 'transparent',
		outline: '0',
		border: '1px solid rgba(0, 92, 185, 0.5)',
		margin: '0',
		borderRadius: '4px',
		padding: '5px 15px',
		cursor: 'pointer',
		userSelect: 'none',
		verticalAlign: 'middle',
		textDecoration: 'none',
		color: '#005CB9',
		fontFamily: 'Open Sans,sans-serif',
		fontWeight: '500',
		fontSize: '0.875rem',
		lineHeight: '1.75',
		textTransform: 'uppercase',
		minWidth: 'fit-content',
		width: 'max-content',
	};
	const inputStyle = { display: 'none' };

	if (native) {
		return (
			<input type="file" onChange={onChange} style={nativeInputStyle} />
		);
	}
	if (filenames) {
		//labelStyle.display = 'none';
		labelStyle.marginRight = '1em';
	}
	return (
		<div
			style={{ display: 'flex', height: '36.5px', alignItems: 'center' }}
		>
			<label style={labelStyle}>
				<input type="file" onChange={onChange} style={inputStyle} />
				{pickerLabel}
			</label>
			{filenames && <div>{filenames}</div>}
		</div>
	);
};
