import { Cell, Pie, PieChart } from 'recharts';

function PieChartVizComponent({ data }) {
	return (
		<PieChart width={200} height={210}>
			<Pie
				cy={100}
				data={data}
				innerRadius={60}
				outerRadius={100}
				dataKey="amount"
				stroke="none"
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={entry.color} />
				))}
			</Pie>
		</PieChart>
	);
}

export function PieChartViz({ data }) {
	if (!data || !Array.isArray(data)) {
		return 'Loading...';
	}

	return <PieChartVizComponent data={data} />;
}
