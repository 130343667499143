import {
	CartesianGrid,
	Line,
	Tooltip,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';

const abbreviateNumber = (v) =>
	Intl.NumberFormat('en-US', {
		notation: 'compact',
		maximumFractionDigits: 1,
	}).format(v);

export function TimeFrameTotalsViz({ data, options, isMoney }) {
	if (!data || !options) {
		return 'Loading...';
	}

	return (
		<TimeFrameTotalsVizComponent
			data={data}
			options={options}
			isMoney={isMoney}
		/>
	);
}

function TimeFrameTotalsVizComponent({ data, options, isMoney = false }) {
	const lines = options.map((x) => {
		return (
			<Line
				type={'natural'}
				dataKey={x.franchiseName}
				stroke={x.color}
				strokeWidth={2}
				strokeDasharray={x.shouldStroke ? '4 4' : undefined}
			/>
		);
	});

	return (
		<ResponsiveContainer width="100%" height={240}>
			<LineChart data={data}>
				<CartesianGrid
					strokeDasharray="0.1 0.1"
					strokeOpacity={0.3}
					strokeWidth="4"
					fill="#fff"
				/>
				<Tooltip />
				<XAxis
					dataKey="week"
					axisLine={false}
					tickLine={false}
					tickMargin={10}
				/>
				<YAxis
					axisLine={false}
					tickFormatter={(x) => {
						const _shortInt = abbreviateNumber(x);
						return isMoney ? `$${_shortInt}` : x;
					}}
					tickLine={false}
					tickMargin={10}
				/>

				{lines}
			</LineChart>
		</ResponsiveContainer>
	);
}
